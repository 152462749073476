.profile-modal .modal-content {
  color: white;
  padding: 1.5em !important;
  border-radius: 15px;
  overflow: hidden;
  background-color: #1a202c;
}

.profile-modal-body {
}

.create-container {
  .connect-wallet {
    padding-inline: 1em;
    color: white;
    font-weight: bold;
    margin-left: 2em;
    img {
      height: 30px;
      margin-right: 3px;
    }
  }

  .navbar {
    height: 80px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.289);

    .nav-link {
      transform: translateY(3px);
    }
  }

  .layout-container {
    width: 100%;
    height: calc(100vh - 80px);
    padding-top: calc(80px);
    display: flex;
    flex-direction: row;
  }

  .sidebar {
    width: 20%;
    height: calc(100vh - 80px);
    border-right: 1px solid rgba(128, 128, 128, 0.257);
    // padding: 1em;
    position: relative;
    .toggle-button {
      display: none;
    }
  }

  .sidebar-inner {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    padding: 1em 0.5em;
    padding-bottom: 10em;
  }

  .sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-block: 15px;
    padding-top: 5px;
    padding-inline: 10px;
    // width: calc(20%);
    background-color: rgb(230, 230, 230);
    button {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 40px;
      img {
        height: 35px;
      }
      span {
        font-weight: 500;
        color: white;
      }
    }
  }
  .preview {
    width: 80%;
    height: calc(100vh - 80px);
    position: relative;
    overflow-y: scroll;
    .navbar {
      position: static !important;
    }
  }

  .collapse-button {
    width: 100%;
    text-align: left;
    background: white;
    border: none;
    border-bottom: 1px solid rgb(212, 212, 212);
    height: 40px;
    font-weight: 500;
    line-height: 40px;
    font-size: 0.9em;
    &:hover {
      cursor: pointer;
    }
  }

  .collapse-card {
    margin-top: 10px;
    padding: 10px;
    border-color: rgba(128, 128, 128, 0.1);
    background-color: rgba(128, 128, 128, 0.068);
  }

  .collapse-card label {
    font-size: 0.8em;
  }

  .collapse-card input {
    font-size: 0.8em;
  }

  .collapse-card input::placeholder {
    color: rgb(201, 201, 201);
  }

  .image-upload {
    width: 100%;
    display: block;
    height: 8em;
    width: 8em;
    background-color: rgba(195, 195, 195, 0.138);
    border: 1px dashed gray;
    border-radius: 12px;
    background-image: url("../../assets/images/upload.png");
    background-size: cover;

    &.filled {
      background-image: unset;
      background-color: whtie;
      &.banner {
        width: 100%;
        height: auto;
      }
    }

    img {
      width: 100%;
    }
  }

  .input-image-filled {
    height: 8em;
    width: 8em;
  }

  input[type="file"] {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .create-container {
    position: relative;
    .sidebar {
      width: 90%;
      position: fixed;
      z-index: 10;
      background-color: white;
      left: -90%;
      transition: all 0.5s ease;
      &.hide {
        left: 0%;
        transition: all 0.5s ease;
      }

      .toggle-button {
        background: rgb(195, 195, 195);
        border: none;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 1.5em;
        width: 40px;
        position: absolute;
        right: -40px;
        bottom: 20%;
        display: block;
        // span {
        //   transform: rotateX("180deg");
        // }
      }
    }

    .preview {
      width: 100%;
    }
  }
}
