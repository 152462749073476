@import "../../assets/scss/variables.scss";
.home {
  width: 100%;

  .connect-wallet {
    padding-inline: 1em;
    color: white;
    font-weight: bold;
    margin-left: 2em;
    img {
      height: 30px;
      margin-right: 3px;
    }
  }

  .navbar {
    height: 80px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.289);
    background-color: white;

    .nav-link {
      transform: translateY(3px);
    }
  }
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: calc(5em + 80px);

    .hero-input {
      display: flex;
      flex-direction: column;
    }

    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1.5em;
      .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(240, 248, 255, 0.605);
        margin-right: 10px;
        border-radius: 8px;
        height: 50px;
        border: 1px solid $success;
        input {
          background-color: transparent;
          border: none;
          width: 8em;
          padding-left: 20px;
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }
        .append {
          padding-inline: 10px;
          color: gray;
        }
      }

      button {
        height: 50px !important;
        padding-inline: 1em;
      }
    }

    .powered {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 0.8em;
      margin-top: 1em;

      img {
        height: 16px;
      }
    }

    .featured-container {
      padding-top: 3em;
      .featured-list {
        display: flex;
        flex-direction: row;

        .featured-item {
          width: 10em;
          border: 1px solid $success;
          padding-block: 1em;
          margin-inline: 10px;
          border-radius: 8px;
          background-color: white;
          img {
            width: 4em;
          }
          h5 {
            padding-top: 8px;
            font-size: 0.9em;
            margin: 0;
            color: darken($success, 1);
            font-weight: bold;
          }
        }
      }
    }
  }
  .webs {
    margin-top: 5em;
    padding-bottom: 5em;
    h2 {
      text-align: center;
    }

    .web-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .web-item {
        width: 32%;
        display: flex;
        flex-direction: row;
        border: 1px solid $success;
        border-radius: 8px;
        margin-bottom: 2em;
        overflow: hidden;
        background-color: lighten($success, 36);
        margin-right: 1.3%;

        .web-image {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            height: 10em;
            width: auto;
          }
        }

        .web-content {
          width: 60%;
          padding: 1em;
          padding-left: 1.5em;
          h5 {
            font-size: 1em;
            margin-bottom: 0 !important;
          }

          .website {
            background-color: lighten($success, 10);
            border-radius: 12px;
            padding-inline: 15px;
            padding-block: 2px;
            font-size: 0.9em;
            display: block;
            overflow-wrap: break-word;
            &.smaller {
              font-size: 0.75em;
            }
            &:hover {
              cursor: pointer;
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;
            padding-top: 1em;
            button {
              background-color: transparent;
              outline: none;
              border: none;
              padding: 0;

              width: 28px;
              height: 28px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 5px;

              &.bg-white {
                background-color: white;
              }
              img {
                width: 28px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .navbar-expand-md .container {
      background-color: white;
    }
    .collapse.navbar-collapse {
      padding-bottom: 1em;
    }

    .hero {
      .input-container {
        flex-direction: column;
        .input-wrapper {
          width: 100%;
          margin-bottom: 15px;
        }
      }
      .featured-container {
        .featured-list {
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          .featured-item {
            width: 7em;
            margin-bottom: 1em;
            h5 {
              font-size: 0.7em;
              padding-inline: 5px;
            }
          }
        }
      }
    }

    .webs {
      .web-list {
        flex-direction: column;
        .web-item {
          width: 100%;
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }
}
